<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DigitizationStatementTable',
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number'), sortable: false },
        { value: 'content_type', text: this.$t('type'), sortable: false },
        { value: 'is_payed', text: this.$t('payment'), sortable: true },
        { value: 'status_document', text: this.$t('status'), sortable: true },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: item.link, params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.sailorDigitizationStatements,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getSailorDigitizationStatements']),
    async getData (params) {
      this.getSailorDigitizationStatements({ ...this.$route.params, params })
    }

  }
}
</script>
